<template>
    <tbody class="location-item" :id="`location_${resultObj['telegram_id']}`">
        <tr class="inform-tr sideBorder">
            <td>
                <div class="d-flext justify-content-center align-items-center text-center">
                    <button @click="show = !show" class="round-btn"><i class="feather"
                            :class="{ 'icon-chevron-down': !show, 'icon-chevron-up': show }"></i></button>
                </div>
            </td>
            <td>
                <div class="d-flex justify-content-center">
                    <photo-fragment v-if="!isUndefined(result.photos) && result.photos.length" class="img-40" :meta="{}"
                        :photo="result.photos[0]" :is-child="false" />
                    <img class="img-40" :src="`${prefix}images/user-found.png`" v-else>
                </div>
            </td>
            <td v-for="column in columns" v-if="column.key != 'actions'">
                <div v-if="column.key === 'distance'" style="display: flex; justify-content: flex-start;">
                  <div style="width: 20px;">
                    <div  v-if="typeof result.approximate_location !== 'undefined' && typeof result.approximate_location.azimuth !== 'undefined'"
                          :style="{ transform: 'rotate('+ result.approximate_location.azimuth+'deg)'}"
                          :content=" $t('geo.azimuth')  + result.approximate_location.azimuth+'°'"
                          v-tippy="{ placement: 'top', arrow: true }"
                    >
                      <i class="feather icon-arrow-up" ></i>
                    </div>
                  </div>
                  <span>{{resultObj[column.key]}}{{$t('geo.meters')}}</span>
                </div>
                <span v-if="column.key !== 'distance'" :class="[`text-column-${column.key}`]" @click="copyText(resultObj[column.key])">
                    {{ column.key === 'name' && resultObj[column.key].length > elipsisNum ?
                        `${resultObj[column.key].slice(0, elipsisNum)}...` : resultObj[column.key] }}
                </span>
                <span v-if="column.key === 'name' && resultObj[column.key].length > elipsisNum" class="info-hover"
                    v-tippy="{ placement: 'top', arrow: true }" :content="resultObj[column.key]"
                    @click="copyText(resultObj[column.key])"><i class="feather icon-info"></i></span>
                    <img src="/images/premium.png" v-tippy="{ placement: 'top', arrow: true }" :content="$t('geo.telegram_premium')" v-if="column.key === 'type' && !isUndefined(resultObj['about']) && resultObj['about'].toLowerCase().includes('premium account')" style="width: 15px; margin-top: -4px;" alt="">
            </td>
            <td class="action-wrapper">
                <b-button-group>
                    <b-dropdown id="dropdown-left" variant="primary">
                        <template #button-content>
                          <i class="feather icon-crosshair"></i><span class="m-l-5 m-r-5">{{
                            $t('params.tools') }}</span>
                        </template>
                        <b-dropdown-item @click="showOnMap(result.locations)">{{ $t('geo.show_on_the_map') }}</b-dropdown-item>
                        <b-dropdown-item @click="detalizeLocation">{{ $t('geo.specify_location') }}</b-dropdown-item>
                        <b-dropdown-item @click="searchMovementHistory" v-if="!meta.parent">{{ $t('geo.show_movement_history') }}</b-dropdown-item>
                        <b-dropdown-item @mouseover.native="showChildSearch = !showChildSearch"
                            @mouseout.native="showChildSearch = !showChildSearch">
                            <span class="d-flex justify-content-between">{{ $t('search_list_item.child_search_by') }}:
                                <span class="d-inline-block"> <b-icon-caret-right-fill :scale="0.6" /></span></span>
                            <div class="sub-menu" v-show="showChildSearch">
                                <b-dropdown-item v-for="searchItem in searchItems"
                                    v-if="!isUndefined(resultObj[searchItem])"
                                    @click.self="showModal(searchItem, resultObj[searchItem])">{{
                                        $t(`params.${searchItem}`) }}</b-dropdown-item>
                                <b-dropdown-item @click="searchPhoto" v-if="result.photos.length">{{ $t('params.photo')
                                    | capitalize }}</b-dropdown-item>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
            </td>
        </tr>
        <tr :class="{ visible: show }" class="sideBorder">
            <td :colspan="columns.length + 2">
                <table class="table m-b-0 ">
                    <tbody>
                        <tr>
                            <td class="align-top" style="width: 150px;">
                                <photo-fragment v-if="result.photos.length" ref="photoSearch" :meta="meta"
                                    :photo="result.photos[0]" :is-child="false" />
                                <img style="width: 128px"
                                    :src="`${prefix}images/no_photo_` + `${$root.$i18n.locale}` + `.png`" v-else>
                            </td>
                            <td>
                                <table class="table m-b-0 last-bb0">
                                    <tbody>
                                        <tr v-for="(item, key) in columnsFull" :key="key"
                                            v-if="!['latitude', 'longitude'].includes(item) && !columns.map(it => it.key).includes(item)">
                                            <td style="width: 150px">{{ $t(`params.${item}`) | capitalize }}</td>
                                            <td>
                                                <span v-if="isUndefined(resultObj[item])">{{ $t('geo.unavailable') }}
                                                    <span class="info-hover m-l-5"
                                                        v-tippy="{ placement: 'top', arrow: true }"
                                                        :content="$t('geo.unavailable_info')">
                                                        <i class="feather icon-info"></i>
                                                    </span>
                                                </span>

                                                <span @click="copyText(resultObj[item])"
                                                    v-html="$options.filters.isLink(item !== 'address'
                                                        || resultObj[item].includes('Unknown') ? resultObj[item] : `${resultObj.address} (${parseFloat(resultObj.latitude).toFixed(4)},${parseFloat(resultObj.longitude).toFixed(4)})`)"
                                                    v-else>
                                                </span>
                                                <span v-if="item === 'address' && !isUndefined(resultObj[item])"
                                                    style="margin-left: 6px" :content="$t('geo.google_map')"
                                                    v-tippy="{ placement: 'top', arrow: true }">
                                                    <a :href="`https://www.google.com/maps?ll=${resultObj.latitude},${resultObj.longitude}`"
                                                        target='_blank'>
                                                        <i class="feather icon-map"></i>
                                                    </a>
                                                </span>

                                                <span v-if="item === 'creation_date' && result.type === 'user'" class="m-l-5"
                                                    :content="$t('geo.created_date')"
                                                    v-tippy="{ placement: 'top', arrow: true }">
                                                    <i class="feather icon-info"></i>
                                                </span>


                                                <span v-if="item === 'creation_date' && result.type === 'user' && isAfterBorderDate(resultObj[item])" class="m-l-5"
                                                    :content="$t('geo.isAfterBorderDate')"
                                                    v-tippy="{ placement: 'top', arrow: true }">

                                                    <i class="feather icon-alert-triangle text-warning"></i>
                                                </span>



                                                <i class="feather icon-search search-in-location m-l-5"
                                                    :content="$t('search.search_by_val')"
                                                    v-tippy="{ placement: 'top', arrow: true }"
                                                    v-if="params.includes(item) && resultObj[item]"
                                                    @click.self="showModal(item, resultObj[item])">
                                                </i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr v-if="meta.parent">
                            <td colspan="2" class="bb0">
                                <table class="table m-b-0">
                                    <tbody>
                                        <template v-for="(group, numArr) in Object.keys(locationGroup)">
                                            <tr v-for="(place, numPlace) in locationGroup[group]">
                                                <td style="width: 150px"><span v-if="numPlace === 0">{{ $t('geo.specifiing') }} #{{ numArr+1 }}</span></td>
                                                <td>
                                                    {{ moment(place.info_date, 'X').format('DD.MM.YYYY HH:mm:ss') }}
                                                    <span v-if="place.address">{{ place.address }}, ({{ place.latitude }}, {{ place.longitude }})
                                                    <span @click="setCircles([place])" class="m-l-5"
                                                        :content="$t('geo.show_on_the_map')"
                                                        v-tippy="{ placement: 'top', arrow: true }">
                                                        <i class="feather icon-map-pin"></i></span></span>
                                                        <span v-else class="ttl"> {{ $t('errors.not_found') }} </span>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>

        </tr>
        
        <b-modal centered ref="search-param-modal" size="xl" hide-footer
            :title="!isUndefined(payload.param) ? this.$t('photo_fragment.search_params') + ': ' + $t(`params.${payload.param}`) : ''">
            <!--      <h4>{{ this.$t("photo_fragment.The search will be completed with the following options: online, stage 1")}}</h4>-->
            <div>
                <name-search-additional-settings :search-payload="payload"
                    v-if="payload.param === 'name' && searchOptions.name.advanced" />
                <!-- <search-options
            v-if="paramsIncluded.includes(dataParam.param)"
            :expanded="true"
            :currentParam="dataParam.param"
            :iterative_search="payload.iterative_search"
            @setValidSearchOptions="setValidSearchOptions"
            @push="getPayload"
            :param="dataParam.param">
        </search-options> -->
                <options-search :param="payload.param" @push="getPayload" @ratesLoading="ratesLoading"
                    @invalid="invalid => setValidSearchOptions(!invalid)" />
            </div>
            <div class="d-grid">

                <!--        TODO move to computed  -->

                <b-button class="mt-3" variant="primary" block @click="searchByParam"
                    :disabled="!searchOptionsValid || (payload.param === 'name' && searchButtonNeedDisable) || loadingRates">{{
                        $t("modal.search") }}</b-button>

                <!--        TODO move to computed  -->

                <b-button class="mt-2" variant="secondary" block @click="cancelModal">{{ $t("modal.cancel")
                    }}</b-button>
            </div>
        </b-modal>
    </tbody>
</template>

<script>
import _ from "underscore";
import ImageProfile from './../ImageProfile.vue'
import filters from "@/mixins/filters";
import PhotoFragment from "../PhotoFragment";
import env from "@/configs/env.json";
import Gallery from "./gallery.vue";
import optionsSearch from "@/components/search/optionsSearch.vue";
import NameSearchAdditionalSettings from "@/components/nameSearchAdditionalSettings";
import { mapGetters } from "vuex";
import moment from "moment";
import imageSearch from '@/components/searchFromResult/imageSearch.vue';
import isdnSearch from '@/components/searchFromResult/isdnSearch.vue';
import telegramIdSearch from '@/components/searchFromResult/telegramIdSearch.vue';
import usernameSearch from '@/components/searchFromResult/usernameSearch.vue';
import nameSearch from "@/components/searchFromResult/nameSearch.vue";
import newLocation from "@/components/searchFromResult/newLocation.vue";

export default {
    setup() {
        return {
            isUndefined: _.isUndefined,
            prefix: env.prefix,
            elipsisNum: 20,
            columnsFull: [
                "telegram_id",
                "name",
                "distance",
                'isdn',
                "username",
                "type",
                "birthdate",
                "creation_date",
                "about",
                "address",
            ],
            services: JSON.parse(localStorage.getItem('services')).map(item => item.name),
            moment,
            toastTemplates: {
                'image': imageSearch,
                'isdn': isdnSearch,
                'telegram_id': telegramIdSearch,
                'username': usernameSearch,
                'name': nameSearch
            }
        }
    },
    name: 'InfoItem',
    props: ['result', 'columns', 'meta'],
    components: { ImageProfile, PhotoFragment, Gallery, optionsSearch, NameSearchAdditionalSettings },
    data() {
        return {
            show: false,
            selectedParam: '',
            selectedValue: '',
            payload: {},
            visualOptions: [],
            paramsIncluded: ['isdn', 'image', 'email', 'car_plate_num', 'username', 'telegram_id', 'name', 'itn'],
            fullAlgorithms: {},
            searchOptionsValid: true,
            loadingRates: false,
            showChildSearch: false,
            searchItems: ['telegram_id', 'isdn', 'name', 'username'],

        }
    },
    mixins: [filters],
    methods: {
        isAfterBorderDate(date){
          return  moment(date).isSameOrAfter('2023-12-01')
        },
        showOnMap(locations) {
            this.$emit('setHeatmap');
            this.$emit('fitBounds', locations)
            this.$emit('scrollSettings', this.resultObj)
            this.$emit('editMap', false);
        },
        setCircles(locations) {
            this.$emit('setCircles', locations);
            this.$emit('editMap', false);
        },
        openDetails() {
            this.show = true;
        },
        closeDetails() {
            this.show = false;
        },
        copyText(text) {
            if (!text) return;
            navigator.clipboard.writeText(text);
            this.$toast.success(this.$t('ui.text_has_been_copied'), {
                position: "top-center",
                timeout: 5000, closeOnClick: true,
                pauseOnFocusLoss: true, pauseOnHover: true,
                draggable: true, draggablePercent: 0.6,
                showCloseButtonOnHover: false, hideProgressBar: true,
                closeButton: "button", icon: true,
                rtl: false
            });
        },
        showModal(param, value) {
            this.$emit('editMap', false);
            this.payload = {};
            this.payload.param = param;
            this.payload.value = value;
            if (param === 'name') {
                this.setAdditionalParamsToNameSearch();
            }
            this.$refs['search-param-modal'].show();
        },
        setAdditionalParamsToNameSearch() {

            if (typeof this.alt_values !== 'undefined' && this.alt_values.length !== 0) {
                if (this.enableAltValues) {
                    this.payload.alt_values = this.alt_values;
                }
            }
            if (typeof this.filters !== 'undefined' && this.filters.length !== 0) {
                let temp = this.filters
                if (!this.enableAddress) {
                    temp = this.filters.filter(el => el.param !== 'address' && el.param !== 'city')
                }
                if (!this.enableAge) {
                    temp = this.filters.filter(el => el.param !== 'birthdate')
                }
                this.payload.filters = temp;
            }
        },
        ratesLoading(val) {
            this.loadingRates = val
        },
        setValidSearchOptions(val) {
            this.searchOptionsValid = val;
        },
        getPayload(data) {
            if (typeof data.service_types !== 'undefined' && data.service_types.length === 0) {
                data.service_types = undefined;
            }
            this.payload = JSON.parse(JSON.stringify(Object.assign(this.payload, data)));
        },
        cancelModal() {
            this.$refs['search-param-modal'].hide();
        },
        searchByParam() {
            let payload = {
                // value: this.dataParam.value,
                // param: this.dataParam.param,
            }
            payload = Object.assign(this.payload)
            // this.payload.value = this.dataParam.value;
            // this.payload.param = this.dataParam.param;
            payload.secondarySearch = true;
            this.$toast.success(this.toastTemplates[payload.param], {
                position: "top-center",
                timeout: 8000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
            this.$store.dispatch('contacts/search', { payload })
            this.$refs['search-param-modal'].hide();
            try {
                this.$matomo.trackEvent('Web Search Child Search ' + payload.param, 'WasClicked');
                let options = this.optionsForAnalytics(payload);
                if (options.length) {
                    this.$matomo.trackEvent('Web Options ' + options, 'WasClicked');
                }
            } catch (e) {
                console.log('ignore error');
            }

        },
        searchInGroups() {
            this.$emit('editMap', false);
            const services = JSON.parse(localStorage.getItem('services')).filter(item => item.search_params.includes('telegram_id')).map(item => item.name);
            const payload = { "param": "telegram_id", "value": parseInt(this.resultObj.telegram_id), "cache_search_enabled": true, "online_search_enabled": true, "iterative_search": false, "services": services }
            this.$store.dispatch('contacts/search', { payload })
            this.$toast.success(this.$t("result.search_added_to_main_page"), {
                position: "top-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        searchPhoto() {
            this.$refs.photoSearch.searchPhoto();
        },
        searchMovementHistory() {
            this.$emit('editMap', false);
            let payload = {
                cache_search_enabled: true,
                online_search_enabled: false,
                param: 'telegram_id',
                value: '',
                sources: ['TelegramGeo']
            }
            let id = this.result.data.find(item => item.param === 'telegram_id')
            payload.value = id.value
            this.$toast.success(newLocation, {
                position: "top-center",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
            this.$store.dispatch('contacts/searchMovementHistory', { payload }).then((data) => {
                this.$store.dispatch('contacts/cacheWithResultsChild', {
                    data,
                    parentKey: this.meta.key,
                    payload,
                    type: "movementHistory"
                })
            })

        },
        detalizeLocation() {
            this.$emit('detailizeLocation', { locations: this.result.locations, resultObj: this.resultObj });
            this.$toast.success(this.$t("geo.detalizedClick"), {
                position: "top-center",
                timeout: 10000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        forceUpdate() {
            this.$forceUpdate();
        },
    },
    computed: {
        ...mapGetters('contacts', ['params', 'searchOptions']),
        ...mapGetters('auth', ['permissions']),
        ...mapGetters("nameSearchAdditionalSettings", ["searchButtonNeedDisable", 'alt_values', 'filters', 'enableAltValues', 'enableAge', 'enableAddress']),
        resultObj() {
            const resObj = {};
            for (const inf of this.result.data) {
                resObj[inf.param] = inf.value;
            }
            resObj['type'] = this.$t('geo.' + this.result.type);
            resObj['distance'] = this.result.locations[0].distance
            //
            // if(typeof  this.result.approximate_location !== 'undefined'){
            //   resObj['distance'] = this.result.approximate_location.azimuth
            // }


            return resObj;
        },
        isShowingDelails() {
            return this.result.photos.length && this.result.data.some(item => this.columns.map(it => it.key).includes(item.param));
        },
        locationGroup(){
            return _.groupBy(this.result.locations, 'info_date')
        },
        modalTitle() {

        }
    },
    watch: {
        alt_values: {
            handler() {
                this.setAdditionalParamsToNameSearch();
            },
            deep: true
        },
        filters: {
            handler() {
                this.setAdditionalParamsToNameSearch();
            },
            deep: true
        }
    },
}
</script>

<style scoped>
.location-item td {
    white-space: normal;
}

.location-item>tr:not(.inform-tr) {
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
}

.location-item>tr.visible {
    display: table-row;
    height: auto;
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
}

.inform-tr {
    display: table-row;
}
.location-item:last-child .sub-menu {
    bottom: 0;
}

/* Your component's CSS styles go here */
</style>
<style>
.sideBorder {
    border-right: solid 1px rgb(222, 226, 230);
    border-left: solid 1px rgb(222, 226, 230);
}

.img-40 img {
    width: 40px !important;
}

.info-hover {
    cursor: pointer;
    transition: color 0.3s;
}

.info-hover:hover {
    color: #3572EF;
}

.action-wrapper {
    width: 1%;
    white-space: nowrap;
}

.search-in-location {
    cursor: pointer;
    transition: color 0.3s;
}

.search-in-location:hover {
    color: #3572EF;
}

.sub-menu {
    position: absolute;
    right: 0;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    z-index: 1000;
}

body .show>.dropdown-menu {
    overflow-x: visible;
    overflow-y: visible;
}

body .show>.dropdown-menu .presentation {
    position: relative;
}

.bb0 {
    border-bottom: 0;
}
.last-bb0 tr:last-child td {
    border-bottom: 0;
}
</style>